

export const lineChartDataDashboard = [
  {
    name: "Item1",
    data: [72, 90, 93, 33, 96, 80, 95, 90, 85],
  },
  {
    name: "Item2",
    data: [76, 97, 95, 51, 94, 0, 85, 60, 90],
  },
  {
    name: "Item3",
    data: [0, 94, 89, 0, 0, 0, 0, 0, 0],
  },
];